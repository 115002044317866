div .photoframe{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border: 1px white solid;
    background-color: black;
    div.photo{
        height: 97%;
        width: 97%;
        display: flex;
        justify-content: end;
        margin: 0;
        padding: 0;
    img{
        height: 10px;
        margin-top: 2px;
        margin-right: 2px;
        mix-blend-mode: exclusion;
        }

    }


}