nav{
    display: flex;
    margin: 0;
    position: fixed;
    z-index: 90;
    width: 100vw;
    height: 50px;
    color: white;
    mix-blend-mode: exclusion;
    font-family: 'Space Mono', monospace;
    font-weight: 400;
    align-items: center;
    font-size: 1.2rem;
    @media screen and (max-width: 700px) {
        font-size: 1rem;
    }
    @media screen and (max-width: 400px) {
        font-size: 0.8rem;
    }
    img.logo{
        min-width: 80px;
        max-width: 200px;
        width:20%;
        justify-self: center;
    
    }
    
    div.menu-principal{
        
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      
        margin-top: 0;
        
        padding: 0;
        list-style-type: none;
        width: 100%;

       
        align-items: stretch; /* Default */
        justify-content: space-between;
       
        
        li{
            list-style-type: none;
            padding: 20px 5% 0% 5%;
            margin: 0;
            @media screen and (max-width: 700px) {
                padding: 20px 5% 0% 5%;
                margin-bottom: 10px;
            }
        }
        div.left{
            grid-column: 1 / 1;
            display: flex;
            justify-content:left;
            margin-left: 10%;
        }
        div.center{
            grid-column: 2 / 2;
            display: flex;
            justify-content: center;
        }
        div.right{
            grid-column: 3 / 3;
            display: flex;
            justify-content: right;
            margin-right: 10%;
        }


       
        a{
            color: white;
            mix-blend-mode: difference;
            text-decoration: none;
        }
        button{
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            margin: 0;
            font: inherit;
            cursor: pointer;
            
        }
    }


    ul.menu-secondary{
       position: absolute;
        margin-top: 0;
        padding: 0;
        li{
            margin-top: 0;
            padding: 0; 
           
        }
        .menu-button:hover{
            color: red;
        }
        @media screen and (max-width: 700px) {
            margin-top: 10px;
        }
    }
    div.nav-item{
     
        margin: 0;
        padding: 0;

        
    }
    .menu-button:hover{
        color: red;
    }

}

