section.digital-collection{
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    @media screen and (max-width: 700px) {
       
        
        justify-content: center;
    }
    @media screen and (max-height: 500px) {
        align-content: center;
    
    }
    div.wrapper{
        display: flex;
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;
        
        align-items: center;
    }
    h1.digital-collection-title{
        position: absolute;
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
        padding: 0;
        font-family: 'Press Start 2P', cursive;
        font-size: 60vh;
        transform: translateX(-20%);
        
   
      
    }

    div.photoframe-group{
        position: absolute;
        margin-top: calc(80vh - 400px);
        
        display: flex;
       
        margin-left: 20%;
        @media screen and (max-width: 700px) {
            margin-top: 20%;
            margin-left: calc(20% - 400px);
            
        
        }
   
    
    }

    div.photoframe-wrapper{
        height: 400px;
        width: 300px;
        position: absolute;
    }
    div.mobile-device{
        margin-left: calc(100% - 500px);
        margin-top: calc(100vh - 400px);
        
        @media screen and (max-width: 700px) {
            margin-top: 0;
            margin-left: 0;
            margin-top: calc(100vh - 300px);
        
        }
        @media screen and (max-height: 500px) {
            margin-top: 10%;
        
        }
    }



}