
section.computer-collection{

    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    div.container-left{
        height: 100%;
        width: 50%;
        background-color: black;
    }

    div.container-right{
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: end;
        img{
            mix-blend-mode: exclusion;
            height: 10%;
        }
      
    }
  
    div .photoframe1{
        position: absolute;
        margin-left: calc(40% - 300px);
        margin-top: calc(90vh - 400px);
        z-index: 1;
    }

    div .photoframe2{
        position: absolute;
        height: 400px;
        width: 300px;
        margin-left: calc(40% - 300px);
        margin-top: calc(90vh - 400px);
        z-index: 2;
        
    }

    div .photoframe3{
        position: absolute;
        height: 400px;
        width: 300px;
        z-index: 3;
        margin-left: calc(40% - 300px);
        margin-top: calc(90vh - 400px);
        

    }

    div.mobile-device{

        z-index: 10;
    }
}
